import { Injectable } from '@angular/core';
import * as AWSSDK from 'aws-sdk';
import { AuthService } from './auth.service';
import { Config } from '../../config/config';
import { Environment } from '../../config/environment';
import { Observable } from 'rxjs';
import { ModalService } from './modal.service';
import { AppUtil } from '../../utils/app.util';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { CredentialsOptions } from 'aws-sdk/lib/credentials';

declare let AWS: any;

@Injectable({
	providedIn: 'root',
})
export class ApiGatewayService {
	additionalParams: any = {};
	env: any;
	credentials: any;

	constructor(
		private environment: Environment,
		private authService: AuthService,
		private config: Config,
		private modalService: ModalService,
		private router: Router
	) {
		this.env = this.environment.getEnvironment();
	}

	getAwsCredentials(): Promise<AWSSDK.Credentials | CredentialsOptions> {
		return new Promise((resolve, reject) => {
			// AWS.config.getCredentials((error, credentials) => {
			// 	if (error) {
			// 		reject(error);
			// 	} else {
			// 		resolve(credentials);
			// 	}
			// });
			AWSSDK.config.getCredentials((error, credentials) => {
				if (error) {
					reject(error);
				} else {
					resolve(credentials);
				}
			});
		});
	}

	securedGet(baseUrl: string, requestUrl: string, domain: string, domainPath: string): Observable<any> {
		// Getting AWS credentials - Temporary secret and access keys
		// Obtain AWS credentials
		return new Observable(observer => {
			this.authService.getCurrentAmplifyUserAuthSession()
				.then(async authSession => {
					console.log('authSession:', authSession);

					if (!authSession) {
						throw new Error('AuthSession not available.');
					}
					if (!domain) {
						domain = this.env.BASE_DOMAIN;
					}
					let credentials;
					if (!authSession?.credentials) {
						try {
							credentials = await this.getAwsCredentials();
						} catch (error) {
							console.error(`securedGet():`, error);
						}
					} else {
						credentials = authSession?.credentials;
					}
					const region = 'us-east-1';
					const endpoint = new AWS.Endpoint(domain);
					const request = new AWS.HttpRequest(endpoint, region);

					let idToken = authSession?.tokens?.idToken?.toString();
					if (!idToken) {
						idToken = JSON.parse(sessionStorage.getItem(this.config.TOKEN_NAME))?.idToken?.jwtToken;
					}
					if (idToken) {
						request.headers['X-auth-token'] = idToken;
					}
					request.headers['host'] = domain;
					request.headers['Content-Type'] = 'application/json';
					request.method = 'GET';
					request.path = domainPath + requestUrl;
					const signer = new AWS.Signers.V4(request, 'execute-api');
					signer.addAuthorization(credentials, AWS.util.date.getDate());
					const client = new AWS.HttpClient();

					const handleHttpClientResponse = (response) => {
						let responseBody = '';
						response.on('data', (chunk) => {
							responseBody += chunk;
						});
						response.on('end', (chunk) => {
							const processedResponse = AppUtil.getResponseObj(responseBody);
							if (processedResponse) {
								processedResponse.statusCode = response.statusCode;
							}
							if (
								response.statusCode === 400 &&
								processedResponse.message &&
								(processedResponse.message.startsWith(this.config.timeOffsetMsgs.sigNotCurrent) ||
									processedResponse.message.startsWith(this.config.timeOffsetMsgs.sigExpired))
							) {
								this.retrySignedFailure(requestUrl, request, client, observer, processedResponse.message);
							} else if (response.statusCode >= 400) {
								observer.error(processedResponse);
								observer.complete();
								if (response.statusCode === 401 || response.statusCode === 403) {
									if (processedResponse.message === 'Session Closed') {
										this.handleConcurrentSessionError(processedResponse);
									} else {
										this.handleServerError(processedResponse);
									}
								} else if (response.statusCode === 500) {
									this.generateConsoleError(request.method, request.path, response);
									// const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;

									if (!requestUrl.includes('/dashboard/vaxpass/')) {
										const message = this.config.exceptions.service.SERVER_ERROR;
										this.showToaster(message);
									}
								} else {
									const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;
									console.log(requestUrl);
									if (
										(response.statusCode === 400 &&
											(requestUrl.startsWith(this.config.url.schedulingApi.getProcedure) ||
												requestUrl.startsWith(this.config.url.schedulingApi.getBookingSimplified) ||
												requestUrl.startsWith(this.config.url.schedulingApi.getAlternateLocations) ||
												requestUrl.startsWith(this.config.url.eheandme.userLoginSession) ||
												requestUrl.startsWith(this.config.url.schedulingApi.getProviders) ||
												requestUrl.startsWith(this.config.url.schedulingApi.getProviderSlots))) ||
										requestUrl.includes('/dashboard/vaxpass/')
									) {
										this.generateConsoleError(request.method, request.path, response);
									} else {
										if (message === this.config.exceptions.service.SESSION_EXPIRY) {
											this.handleServerError(processedResponse);
										} else {
											this.generateConsoleError(request.method, request.path, response);
											this.showToaster(message);
										}
									}
								}
							} else {
								observer.next(processedResponse);
								observer.complete();
							}
						});
					}

					const handleHttpClientError = (error) => {
						this.generateConsoleError(request.method, request.path, error);
						if (
							error.status === 401 ||
							error.status === 403 ||
							error.message === this.config.exceptions.service.SESSION_EXPIRY
						) {
							if (error.message === 'Session Closed') {
								this.handleConcurrentSessionError(error);
							} else {
								this.handleServerError(error);
							}
						} else if (error.status === 500) {
							// const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;
							const message = this.config.exceptions.service.SERVER_ERROR;
							this.showToaster(message);
						} else {
							this.showToaster();
						}
						observer.error(error);
						observer.complete();
					}

					client.handleRequest(request, {}, handleHttpClientResponse.bind(this), handleHttpClientError.bind(this));
				}, error => {
					observer.error(error);
					observer.complete();
				});
		});
	}

	securedPost(baseUrl: string, requestUrl: string, body, domain: string, domainPath: string): Observable<any> {
		// Getting AWS credentials - Temporary secret and access keys
		// this.authService.getAwsCredentials();
		// Obtain AWS credentials
		return new Observable(observer => {
			this.authService.getCurrentAmplifyUserAuthSession()
				.then(async authSession => {
					if (!authSession) {
						throw new Error('AuthSession not available.');
					}
					if (!domain) {
						domain = this.env.BASE_DOMAIN;
					}

					let credentials;
					if (!authSession?.credentials) {
						try {
							credentials = await this.getAwsCredentials();
						} catch (error) {
							console.error(`securedGet():`, error);
						}
					} else {
						credentials = authSession?.credentials;
					}

					const region = 'us-east-1';
					const endpoint = new AWS.Endpoint(domain);
					const request = new AWS.HttpRequest(endpoint, region);

					let idToken = authSession?.tokens?.idToken?.toString();
					if (!idToken) {
						idToken = JSON.parse(sessionStorage.getItem(this.config.TOKEN_NAME))?.idToken?.jwtToken;
					}
					if (idToken) {
						request.headers['X-auth-token'] = idToken;
					}
					request.headers['host'] = domain;
					request.headers['Content-Type'] = 'application/json';
					request.method = 'POST';
					request.path = domainPath + requestUrl;
					request.body = JSON.stringify(body);

					const signer = new AWS.Signers.V4(request, 'execute-api');
					signer.addAuthorization(credentials, AWS.util.date.getDate());

					const client = new AWS.HttpClient();

					const handleHttpClientResponse = (response) => {
						let responseBody = '';
						response.on('data', (chunk) => {
							responseBody += chunk;
						});
						response.on('end', (chunk) => {
							const processedResponse = AppUtil.getResponseObj(responseBody);
							if (processedResponse) {
								processedResponse.statusCode = response.statusCode;
							}
							if (
								response.statusCode === 400 &&
								(processedResponse.message.startsWith(this.config.timeOffsetMsgs.sigNotCurrent) ||
									processedResponse.message.startsWith(this.config.timeOffsetMsgs.sigExpired))
							) {
								this.retrySignedFailure(requestUrl, request, client, observer, processedResponse.message);
							} else if (response.statusCode >= 400) {
								this.generateConsoleError(request.method, request.path, response);
								observer.error(processedResponse);
								observer.complete();
								if (response.statusCode === 401 || response.statusCode === 403) {
									this.handleServerError(processedResponse);
								} else if (response.statusCode === 500) {
									this.generateConsoleError(request.method, request.path, response);
									// const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;
									const message = this.config.exceptions.service.SERVER_ERROR;
									this.showToaster(message);
								} else {
									const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;
									// eslint-disable-next-line max-len
									if (
										(response.statusCode === 400 &&
											(requestUrl.includes('/dashboard/vaxpass/') ||
												((requestUrl.endsWith('/emails/verification') || requestUrl.endsWith('/validateSSN')) &&
													requestUrl.startsWith(this.config.url.profileApi.baseUrl)))) ||
										(response.statusCode === 409 && requestUrl.startsWith(this.config.url.pulseVirtualApi.consultation))
									) {
									} else {
										if (message === this.config.exceptions.service.SESSION_EXPIRY) {
											this.handleServerError(processedResponse);
										} else {
											this.generateConsoleError(request.method, request.path, response);
											this.showToaster(message);
										}
									}
								}
							} else {
								observer.next(processedResponse);
								observer.complete();
							}
						});
					}

					const handleHttpClientError = (error) => {
						this.generateConsoleError(request.method, request.path, error);
						if (
							error.status === 401 ||
							error.status === 403 ||
							error.message === this.config.exceptions.service.SESSION_EXPIRY
						) {
							this.handleServerError(error);
						} else if (error.status === 500) {
							// const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;
							const message = this.config.exceptions.service.SERVER_ERROR;
							this.showToaster(message);
						} else {
							this.showToaster();
						}
						observer.error(error);
						observer.complete();
					}

					client.handleRequest(request, {}, handleHttpClientResponse.bind(this), handleHttpClientError.bind(this));
				}, error => {
					observer.error(error);
					observer.complete();
				});
		});
	}

	securedPut(baseUrl: string, requestUrl: string, body, domain: string): Observable<any> {
		// Getting AWS credentials - Temporary secret and access keys
		// this.authService.getAwsCredentials();
		// Obtain AWS credentials
		return new Observable(observer => {
			this.authService.getCurrentAmplifyUserAuthSession()
				.then(async authSession => {
					if (!authSession) {
						throw new Error('AuthSession not available.');
					}
					if (!domain) {
						domain = this.env.BASE_DOMAIN;
					}

					let credentials;
					if (!authSession?.credentials) {
						try {
							credentials = await this.getAwsCredentials();
						} catch (error) {
							console.error(`securedGet():`, error);
						}
					} else {
						credentials = authSession?.credentials;
					}
					const region = 'us-east-1';
					const endpoint = new AWS.Endpoint(domain);
					const request = new AWS.HttpRequest(endpoint, region);

					let idToken = authSession?.tokens?.idToken?.toString();
					if (!idToken) {
						idToken = JSON.parse(sessionStorage.getItem(this.config.TOKEN_NAME))?.idToken?.jwtToken;
					}
					if (idToken) {
						request.headers['X-auth-token'] = idToken;
					}
					request.headers['host'] = domain;
					request.headers['Content-Type'] = 'application/json';
					request.method = 'PUT';
					request.path = '/base' + requestUrl;
					request.body = JSON.stringify(body);

					const signer = new AWS.Signers.V4(request, 'execute-api');
					signer.addAuthorization(credentials, AWS.util.date.getDate());

					const client = new AWS.HttpClient();

					const handleHttpClientResponse = (response) => {
						let responseBody = '';
						response.on('data', (chunk) => {
							responseBody += chunk;
						});
						response.on('end', (chunk) => {
							const processedResponse = AppUtil.getResponseObj(responseBody);
							if (processedResponse) {
								processedResponse.statusCode = response.statusCode;
							}
							if (
								response.statusCode === 400 &&
								(processedResponse.message.startsWith(this.config.timeOffsetMsgs.sigNotCurrent) ||
									processedResponse.message.startsWith(this.config.timeOffsetMsgs.sigExpired))
							) {
								this.retrySignedFailure(requestUrl, request, client, observer, processedResponse.message);
							} else if (response.statusCode >= 400) {
								this.generateConsoleError(request.method, request.path, response);
								observer.error(processedResponse);
								observer.complete();
								if (response.statusCode === 401 || response.statusCode === 403) {
									this.handleServerError(processedResponse);
								} else if (response.statusCode === 500) {
									this.generateConsoleError(request.method, request.path, response);
									// const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;
									const message = this.config.exceptions.service.SERVER_ERROR;
									this.showToaster(message);
								} else if (
									response.statusCode === 400 &&
									processedResponse.message === this.config.exceptions.service.appointments.SLOT_BOOKED
								) {
								} else {
									const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;
									if (message === this.config.exceptions.service.SESSION_EXPIRY) {
										this.handleServerError(processedResponse);
									} else {
										this.generateConsoleError(request.method, request.path, response);
										this.showToaster(message);
									}
								}
							} else {
								observer.next(processedResponse);
								observer.complete();
							}
						});
					}

					const handleHttpClientError = (error) => {
						this.generateConsoleError(request.method, request.path, error);
						if (
							error.status === 401 ||
							error.status === 403 ||
							error.message === this.config.exceptions.service.SESSION_EXPIRY
						) {
							this.handleServerError(error);
						} else if (error.status === 500) {
							// const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;
							const message = this.config.exceptions.service.SERVER_ERROR;
							this.showToaster(message);
						} else if (
							error.status === 400 &&
							error.message === this.config.exceptions.service.appointments.SLOT_BOOKED
						) {
						} else {
							this.showToaster();
						}
						observer.error(error);
						observer.complete();
					}

					client.handleRequest(request, {}, handleHttpClientResponse.bind(this), handleHttpClientError.bind(this));
				}, error => {
					observer.error(error);
					observer.complete();
				});
		});
	}

	securedDelete(baseUrl: string, requestUrl: string, body, domain: string): Observable<any> {
		// Getting AWS credentials - Temporary secret and access keys
		// this.authService.getAwsCredentials();
		// Obtain AWS credentials
		return new Observable(observer => {
			this.authService.getCurrentAmplifyUserAuthSession()
				.then(async authSession => {
					if (!authSession) {
						throw new Error('AuthSession not available.');
					}
					if (!domain) {
						domain = this.env.BASE_DOMAIN;
					}

					let credentials;
					if (!authSession?.credentials) {
						try {
							credentials = await this.getAwsCredentials();
						} catch (error) {
							console.error(`securedGet():`, error);
						}
					} else {
						credentials = authSession?.credentials;
					}
					const region = 'us-east-1';
					const endpoint = new AWS.Endpoint(domain);
					const request = new AWS.HttpRequest(endpoint, region);

					let idToken = authSession?.tokens?.idToken?.toString();
					if (!idToken) {
						idToken = JSON.parse(sessionStorage.getItem(this.config.TOKEN_NAME))?.idToken?.jwtToken;
					}
					if (idToken) {
						request.headers['X-auth-token'] = idToken;
					}
					request.headers['host'] = domain;
					request.headers['Content-Type'] = 'application/json';
					request.method = 'DELETE';
					request.path = '/base' + requestUrl;
					request.body = JSON.stringify(body);

					const signer = new AWS.Signers.V4(request, 'execute-api');
					signer.addAuthorization(credentials, AWS.util.date.getDate());

					const client = new AWS.HttpClient();

					const handleHttpClientResponse = (response) => {
						let responseBody = '';
						response.on('data', (chunk) => {
							responseBody += chunk;
						});
						response.on('end', (chunk) => {
							const processedResponse = AppUtil.getResponseObj(responseBody);
							if (processedResponse) {
								processedResponse.statusCode = response.statusCode;
							}
							if (response.statusCode >= 400 && requestUrl.startsWith(this.config.url.eheandme.userLoginSession)) {
								observer.error(processedResponse);
								observer.complete();
							} else if (
								response.statusCode === 400 &&
								(processedResponse.message.startsWith(this.config.timeOffsetMsgs.sigNotCurrent) ||
									processedResponse.message.startsWith(this.config.timeOffsetMsgs.sigExpired))
							) {
								this.retrySignedFailure(requestUrl, request, client, observer, processedResponse.message);
							} else if (response.statusCode >= 400) {
								this.generateConsoleError(request.method, request.path, response);
								observer.error(processedResponse);
								observer.complete();
								if (response.statusCode === 401 || response.statusCode === 403) {
									if (requestUrl.startsWith(this.config.url.eheandme.userLoginSession)) {
										this.logOut();
									} else {
										this.handleServerError(processedResponse);
									}
								} else if (response.statusCode === 500) {
									this.generateConsoleError(request.method, request.path, response);
									// const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;
									const message = this.config.exceptions.service.SERVER_ERROR;
									this.showToaster(message);
								} else {
									const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;
									if (message === this.config.exceptions.service.SESSION_EXPIRY) {
										this.handleServerError(processedResponse);
									} else {
										this.generateConsoleError(request.method, request.path, response);
										this.showToaster(message);
									}
								}
							} else {
								observer.next(processedResponse);
								observer.complete();
							}
						});
					}

					const handleHttpClientError = (error) => {
						this.generateConsoleError(request.method, request.path, error);
						if (error.status >= 400 && requestUrl.startsWith(this.config.url.eheandme.userLoginSession)) {
						} else if (
							error.status === 401 ||
							error.status === 403 ||
							error.message === this.config.exceptions.service.SESSION_EXPIRY
						) {
							this.handleServerError(error);
						} else if (error.status === 500) {
							// const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;
							const message = this.config.exceptions.service.SERVER_ERROR;
							this.showToaster(message);
						} else {
							this.showToaster();
						}
						observer.error(error);
						observer.complete();
					}

					client.handleRequest(request, {}, handleHttpClientResponse.bind(this), handleHttpClientError.bind(this));
				}, error => {
					observer.error(error);
					observer.complete();
				});
		});
	}

	retrySignedFailure(requestUrl: string, request: AWS.HttpRequest, client: any, observer: any, message: string) {
		const self = this;
		self.determineOffsetFromFailure(message);
		const signer1 = new AWS.Signers.V4(request, 'execute-api');

		this.authService.getCurrentAmplifyUserAuthSession()
			.then(async authSession => {

				let idToken = authSession?.tokens?.idToken?.toString();
				if (!idToken) {
					idToken = JSON.parse(sessionStorage.getItem(this.config.TOKEN_NAME))?.idToken?.jwtToken;
				}
				if (idToken) {
					request.headers['X-auth-token'] = idToken;
				}

				let credentials;
				if (!authSession?.credentials) {
					try {
						credentials = await this.getAwsCredentials();
					} catch (error) {
						console.error(`securedGet():`, error);
					}
				} else {
					credentials = authSession?.credentials;
				}

				signer1.addAuthorization(credentials, AWS.util.date.getDate());
				client.handleRequest(request, {}, function (response) {
					let responseBody = '';
					response.on('data', function (chunk) {
						responseBody += chunk;
					});
					response.on('end', function (chunk) {
						const processedResp = AppUtil.getResponseObj(responseBody);
						if (processedResp) {
							processedResp.statusCode = response.statusCode;
						}
						if (response.statusCode >= 400) {
							self.handleResponseErrors(observer, processedResp, response, requestUrl);
						} else {
							observer.next(processedResp);
							observer.complete();
						}
					});
				});
			});
	}

	// This method will take the mmessage sent from AWS and determine the offset
	determineOffsetFromFailure(message: string) {
		const self = this;
		let currDateTimeStr = '';
		if (message.startsWith(self.config.timeOffsetMsgs.sigNotCurrent)) {
			currDateTimeStr = message.substring(82, 98);
		} else if (message.startsWith(self.config.timeOffsetMsgs.sigExpired)) {
			currDateTimeStr = message.substring(74, 90);
		}
		const parsedDt = moment(currDateTimeStr, self.config.timeOffsetMsgs.awsDateTimeFormat).toDate();
		AWSSDK.config.correctClockSkew = true;
		AWSSDK.config.systemClockOffset = new Date(parsedDt).getTime() - new Date().getTime();
	}

	// This can be used to refactor in the future
	handleResponseErrors(observer: any, processedResponse: any, resp: any, requestUrl: string) {
		const self = this;
		observer.error(processedResponse);
		observer.complete();
		if (resp.statusCode === 401 || resp.statusCode === 403) {
			self.handleServerError(processedResponse);
		} else if (resp.statusCode === 500) {
			const message = self.config.exceptions.service.SERVER_ERROR;
			self.showToaster(message);
		} else {
			const message = processedResponse.error ? processedResponse.error.message : processedResponse.message;
			if (
				resp.statusCode === 400 &&
				(requestUrl.startsWith(self.config.url.schedulingApi.getProcedure) ||
					requestUrl.startsWith(self.config.url.schedulingApi.getAlternateLocations) ||
					requestUrl.startsWith(self.config.url.eheandme.userLoginSession))) {
			} else {
				if (message === self.config.exceptions.service.SESSION_EXPIRY) {
					self.handleServerError(processedResponse);
				} else {
					self.showToaster(message);
				}
			}
		}
	}

	handleServerError(error) {
		this.modalService.openSessionExpireModal('SESSION EXPIRED', 'Please login to continue');
		this.InactivateConcurrentSession();
	}
	showToaster(message?: string) {
		this.modalService.showToaster(message);
	}

	generateConsoleError(method: string, url: string, error: any) {
		console.error(`[${ApiGatewayService.name}][${this.generateConsoleError.name}]`, 'SERVICE ERROR', method, url, error);
	}

	InactivateConcurrentSession() {
		this.securedDelete(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.eheandme.userLoginSession,
			'',
			''
		).subscribe({
			next: (value) => { this.authService.logoutUser(); },
			error: (e) => { this.authService.logoutUser(); }
		});
	}

	handleConcurrentSessionError(error) {
		this.modalService.openSessionExpireModal(
			'SESSION EXPIRED',
			'You have an active session in another device. Please login to continue'
		);
		this.InactivateConcurrentSession();
	}

	logOut() {
		this.authService.logoutUser();
		this.router.navigate(['/login'], { queryParams: { clear: true } });
	}
}
