import { Component, HostListener, OnInit, OnDestroy, Inject } from '@angular/core';
import { ToasterService } from '../../services/core/toaster.service';
import { FindAppointmentService } from '../../services/scheduling/find-appointment/find-appointment.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { DOCUMENT } from '@angular/common';

declare let $: any; //  use of jQuery-not recommended though
/**
 * Home component,
 *
 * Login component will be displayed inside home component in the case of normal flow
 * It's base component for login component and this will not be there in the case of Marketo
 */
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
	simplified = true;
	disableToaster: boolean = false;
	applyVariation: boolean;
	inactivityLogoutDetected = false;
	inactivityErrorText = "You've been logged out due to inactivity.";

	private unsubscribe$ = new Subject<void>();
	/**
	 * When user clicks, closing toaster
	 */
	@HostListener('click') close() {
		this.toaster.hide();
	}

	constructor(
		public toaster: ToasterService,
		private findApptService: FindAppointmentService,
		private route: ActivatedRoute,
		@Inject(DOCUMENT) private _document
	) {
		route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
			if (params.clear) {
				this.disableToaster = true;
			}
			if (params.m && params.m === 'inactive') {
				this.inactivityLogoutDetected = true;
			}
		});
		route.data.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
			this.applyVariation = data.applyVariation;
			if (!this.applyVariation && !this.inactivityLogoutDetected) {
				window.history.pushState({ page: 1 }, '', '/');
			}
		});
	}

	ngOnInit() {
		// removeItem isSchedulerActivated on home view, fixes issue w/ A/B test booking simplified flow DE-2849
		sessionStorage.removeItem('isSchedulerActivated');
		if (!this.disableToaster) this.getAppointmentDataForToaster();
	}

	ngAfterViewInit() {
		if (window['dataLayer']) {
			window['dataLayer'].push({ event: 'optimize.activate-nonemail' });
			// window['dataLayer'].push({'event': 'optimize.activate-toaster'});
			window['dataLayer'].push({ event: 'optimize.activate-banner' });
		}
	}


	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	/**
	 * Gets appointment data for toaster
	 */
	getAppointmentDataForToaster() {
		this.findApptService
			.getAppointmentDataForToaster(7)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(
				res => {
					// Show toaster when optimize is enabled and toaster is not shown before
					if (res.count !== 0) {
						// disabling toaster, as it is overlapping with chat window
						// this.toaster.show(res);
					}
				},
				err => {
					console.error(`[${HomeComponent.name}][${this.getAppointmentDataForToaster.name}]`, 'An error occurred while getting appointment count', err);
				}
			);
	}

	getWrapperClass(): string {
		return this.applyVariation ? 'login-variation-wrapper' : 'login-wrapper';
	}
}
