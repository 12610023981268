import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Environment } from '../../config/environment';

/**
 * Auth Guard Service implements CanActivate & CanActivateChild
 * Helps to restrict route access if user unauthenticated
 */
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
	/** holds Environment configuration  */
	env: any;
	/**
	 * Auth Guard Service Constructor
	 * @param router - Angular Router
	 * @param authService - Auth Service Object
	 * @param environment - Environment Config object
	 */
	constructor(
		private router: Router,
		private authService: AuthService,
		private environment: Environment,
	) {
		this.env = this.environment.getEnvironment();
	}

	/**
	 * Implementing canActivate interface
	 * @param route - ActivatedRouteSnapshot
	 * @param state - RouterStateSnapshot
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		// check if the jwtToken is not expired
		if (!this.authService.isTokenExpired()) {
			return true;
		}
		// if user is not authenticated setting the unauth bucket for analytics data
		const params = state.root.queryParams;
		if (params && Object.keys(params).length !== 0) {
			this.authService.setUtmParameters(params);
		}
		// redirect the user to login screen
		this.router.navigate(['login'], {
			queryParams: { returnUrl: encodeURI(state.url) },
		});
		return false;
	}

	/**
	 * Checking condition for enabling the child routes
	 * @param route - ActivatedRouteSnapshot
	 * @param state - RouterStateSnapshot
	 */
	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}
}
