import { FormControl } from '@angular/forms';
import { Moment } from 'moment';
import { Location } from 'src/app/beans/dashboard/dashboard-module.bean';

/** Contains Generic Beans */

/** Phone Bean */
export interface Phone {
	/** Type - work/home */
	type: string;
	/** 10-digit number */
	number: string;
	/** extension if applicable */
	extension?: string;
}

/** User Bean */
export interface User {
	/** Holds patient id */
	patientID: string;
	/** holds epms pid of user */
	epmsPID: number;
	/** holds firstname of user */
	firstName: string;
	/** holds last name of user */
	lastName: string;
	/** holds user gender */
	gender: string;
	/** holds date of birth */
	dateOfBirth: string;
	/** holds address object */
	address: Address;
	/** holds employer details */
	employer: string;
	/** holds user email */
	email: string;
	/** holds phone number */
	phone: Phone[] | Phone;
}

/** Address Bean */
export interface Address {
	/** Holds address details - line 1 */
	line1: string;
	/** holds address details  - line 2 */
	line2?: string;
	/** holds address details - country */
	country: string;
	/** holds address details - city */
	city: string;
	/** holds address details - state */
	state: string;
	/** holds address details - city */
	zip: string;
}

/** HH Options Bean */
export interface HHOptions {
	/** id */
	id?: number;
	/** form control name */
	controlName?: string;
	/** holds options type */
	optionType?: string;
	/** holds sub type*/
	subType?: string;
	/** holds attribute name */
	name: string;
	/** holds display order */
	displayOrder?: number;
	/** holds user gender */
	gender?: string;
	/** holds short text */
	shortText?: string;
	/** holds start date */
	startDate?: string;
	/** holds end date */
	endDate?: string;
	/** to check if featured */
	featured?: boolean;
	/** holds conditional fields - optional */
	conditionalField?: boolean;
}

export interface HAFamilyHistoryOption {
	id: number;
	name: string;
	displayOrder: number;
	score: number;
	hideCheckbox?: boolean;
}

export interface HAFamilyHistoryOptionUI extends HAFamilyHistoryOption {
	checked?: boolean;
	diagnosis?: FormControl<number[]>;
	showErrorMessage?: boolean;
}

export interface HAFamilyHistoryOptionBaseDTO {
	optionID: number;
}

export interface HAFamilyHistoryOptionDTO extends HAFamilyHistoryOptionBaseDTO {
	diagnosis?: HAFamilyHistoryOptionBaseDTO[];
}

export interface HAFamilyHistoryDTO {
	healthAssessmentID: number;
	relativeResponses: HAFamilyHistoryOptionDTO[];
}

export interface ProcedureItem {
	recentAppointmentDate?: string;
	id: number;
	name: string;
	eligibleDate?: string;
	preSelected?: boolean;
	disableForEheFacility?: boolean;
	selected?: boolean;
	/** User added sub-procedure by clicking 'Remove` */
	userAdded?: boolean;
	/** User removed sub-procedure by clicking 'Add` */
	userRemoved?: boolean;
}

/*** Procedure data type*/
export interface Procedure {
	/** Procedure object */
	procedure: ProcedureItem;
	/** list of sub procedure object */
	subProcedures?: ProcedureItem[];
	selected?: boolean;
}

export interface SchedulingProvidersResponseBase {
	procedureId: number;
	subProcedureIds?: number[];
	showCreditCardInfo?: boolean;
	displayAdminGenderPreference?: boolean;
	defaultLocation?: boolean;
	lastVisitedLocationId?: number;
}

export interface ProvidersResponse extends SchedulingProvidersResponseBase {
	procedureId: number;
	subProcedureIds?: number[];
	showCreditCardInfo?: boolean;
	displayAdminGenderPreference?: boolean;
	defaultLocation?: boolean;
	lastVisitedLocationId?: number;
	facilities?: Location[];
	/**
	 * [UI only Property] \
	 * To store extended properties in order to call APIs and for the ease of use and status identification.
	 */
	providerFacilities?: ProviderLocation[];
}

export class ProviderLocation {
	/** [UI only Property] \
	 * To indicate whether the API is still loading slots for the facility/location to which this SlotsList is attached to. */
	isSlotsFetchingComplete?: boolean;
	/** [UI only Property] \
	 * To indicate whether the API Request has timed out. */
	isApiRequestTimedOut?: boolean;
	/** [UI only Property] \
	 * To store other error message received from API */
	apiErrorMessage?: string;
	location: Location;
	// startDate: string;
	bookingAllowedBufferDate?: string;

	constructor(location: Location) {
		this.location = location;
	}
}

export interface SchedulingResponse extends SchedulingProvidersResponseBase {
	facilitySlotsList?: LocationInventory[];
}

export interface LocationInventory extends ProviderLocation {
	slotsList?: SlotsList;
}

export interface SlotsList {
	message?: string;
	// date?: string;
	slots?: InventorySlot[];
}

export interface InventorySlot {
	inventoryId: number;
	date: string;
	dateMoment?: Moment;
	timeSlots?: TimeSlot[];
}

export interface TimeSlot {
	time: string;
	procedureStaffSlot: ProcedureStaffSlot;
	subProcedureStaffSlots?: ProcedureStaffSlot[];
}

export interface ProcedureStaffSlot {
	procedureId: number;
	staffId: string;
	time: string;
}

export interface TimeSlotSelectionMap {
	[timeSlotSelectionMapObjectID: string]: SelectableTimeSlot;
}

export type SelectableInventorySlot = Omit<InventorySlot, 'timeSlots'> & { timeSlot: TimeSlot };

export class SelectableTimeSlot {
	isSelected: boolean;
	location: Location;
	inventorySlot: SelectableInventorySlot;

	constructor(location: Location, inventorySlot: InventorySlot, timeSlot: TimeSlot) {
		this.location = location;
		this.inventorySlot = {
			inventoryId: inventorySlot.inventoryId,
			date: inventorySlot.date,
			timeSlot: timeSlot,
		};
	}

	get id(): string {
		if (this.inventorySlot?.timeSlot) {
			return SelectableTimeSlot.getID(this.inventorySlot, this.inventorySlot.timeSlot);
		}
		return '';
	}

	static getID(inventorySlot: InventorySlot | SelectableInventorySlot, timeSlot: TimeSlot) {
		return `${inventorySlot.inventoryId} ${inventorySlot.date} ${timeSlot.time}`;
	}
}

export interface ForgotPasswordPreCheckRequest {
	firstName: string,
	lastName: string,
	dateOfBirth: string,
	email: string,
}

export interface MfaActivatedStatusResponse {
	hasMfaHistory: boolean,
	isMfaActive: boolean,
	username: string,
}

export enum MfaSessionStorageKeys {
	MFA_ACTIVATED_AT = 'MFA_ACTIVATED_AT',
	GLOBAL_MFA_STATUS = 'GLOBAL_MFA_STATUS',
	MFA_DISABLED_REASON = 'MFA_DISABLED_REASON',
	IS_MFA_SET_AT_USER_LEVEL = 'IS_MFA_SET_AT_USER_LEVEL',
}

export enum MfaSetupState {
	FIRST_TIME = 'FIRST_TIME',
	ALREADY_SET = 'ALREADY_SET',
	UNDEFINED = 'UNDEFINED'
}

export enum MfaUiCommands {
	SHOW = 'SHOW',
	HIDE = 'HIDE',
}
