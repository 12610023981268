import { Directive, OnChanges, Input, HostListener } from '@angular/core';
import { EventTrackingBean } from '../../../beans/analytics/analytics-module.bean';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
/**
 * Directive to track button click events in google analytics
 */
@Directive({
	selector: '[appGoogleAnalytics]',
})
export class GoogleAnalyticsDirective implements OnChanges {
	/** Data passing with directive */
	@Input() eventData: EventTrackingBean;
	/**
	 * Host listener to track click event on directive element
	 * @param $event
	 */
	@HostListener('click', ['$event']) onClick($event) {
		setTimeout(() => {
			this.sendData();
		}, 10);
	}

	/**
	 * Host listener to track datechange event on directive element - mat datepicker
	 * @param $event
	 */
	@HostListener('dateChange', ['$event']) onDateChange($event) {
		setTimeout(() => {
			this.sendData();
		}, 10);
	}

	/**
	 * Host listener to track calander open event on directive element
	 * @param $event
	 */
	@HostListener('opened', ['$event']) onOpen($event) {
		setTimeout(() => {
			this.sendData();
		}, 10);
	}

	/**
	 * Google Analytics Service Constructor
	 * @param authService - Auth Service
	 * @param environment - Environment service
	 * @param http - Angular Http Client
	 */
	constructor(
		private analyticsService: AnalyticsService,
	) {
	}

	ngOnChanges(changes) {
		if (changes && changes.data) {
			this.eventData = changes.data.currentValue;
		}
	}

	sendData() {
		this.analyticsService.sendGaEvent(this.eventData);
	}
}
