import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { METHOD_TYPE } from '../../constants/service.constants';
import { JwtHelper } from './jwt-helper.service';
import { Environment } from '../../config/environment';
import { ServiceRouter } from './http-service-router.service';
import { Config } from '../../config/config';
import { ProfileInfoBean, ProfileRelatedInfoBean } from 'src/app/beans/account/account-module.bean';
import { DataCarrierService } from './data-carrier.service';
import { datadogRum } from '@datadog/browser-rum';
import { ForgotPasswordPreCheckRequest, MfaActivatedStatusResponse } from 'src/app/modules/shared/types';

@Injectable()
export class ProfileService {
	firstName: BehaviorSubject<string>;
	lastName: BehaviorSubject<string>;
	gender: BehaviorSubject<string>;
	constructor(
		private jwtService: JwtHelper,
		private environment: Environment,
		private serviceRouter: ServiceRouter,
		private config: Config,
		private dataCarrierService: DataCarrierService,
	) {
		this.firstName = new BehaviorSubject<string>('');
		this.lastName = new BehaviorSubject<string>('');
		this.gender = new BehaviorSubject<string>('');
	}

	getProfileInfo(): Observable<ProfileRelatedInfoBean> {
		const epmsPID = this.jwtService.getEpmsId();
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.profileApi.baseUrl + `/${epmsPID}/primary-info`,
			METHOD_TYPE.SECURED_GET,
			''
		).pipe(
			tap((response) => {
				if (response && response.profileInfo && (response.profileInfo.patientID || response.profileInfo.epmsPID)) {
					datadogRum.setUser({
						id: response.profileInfo?.epmsPID,
						name: response.profileInfo?.patientID
					});
				}
			})
		);
	}

	getSimplifiedProfileInfo(token: string): Observable<ProfileInfoBean> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.profileApi.primaryInfo + `?tag=${token}`,
			METHOD_TYPE.GENERAL_GET,
			''
		);
	}

	updateDataCarrier(): Observable<any> {
		return new Observable(obs => {
			this.getProfileInfo().subscribe(response => {
				const res = this.dataCarrierService.update('profile', response);
				obs.next(res);
				obs.complete();
			});
		});
	}

	setFirstName(firstName) {
		this.firstName.next(firstName);
	}

	getFirstName() {
		return this.firstName;
	}

	setLastName(lastName) {
		this.lastName.next(lastName);
	}

	getLastName() {
		return this.lastName;
	}

	setGender(gender) {
		this.gender.next(gender);
	}

	getGender() {
		return this.gender;
	}

	/**
	 * Non- email Booking Simplified token check
	 */
	loadBookingSimplified(bsValues) {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.profileApi.bookingSimplifiedToken,
			METHOD_TYPE.GENERAL_POST_WITHOUT_HEADERS,
			bsValues
		);
	}

	loadPWEligibility(bsValues) {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().PULSE_WELLNESS_URL + this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN,
			this.config.url.profileApi.pwEligibility,
			METHOD_TYPE.GENERAL_POST_WITHOUT_HEADERS,
			bsValues
		);
	}
	/**
	 * Forgot Email api
	 */
	forgotEmail(userDetails) {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.profileApi.forgotEmail,
			METHOD_TYPE.GENERAL_POST_WITHOUT_HEADERS,
			userDetails
		);
	}

	GetSessionInfo() {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.eheandme.userLoginSession,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	inactivateSession() {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.eheandme.userLoginSession,
			METHOD_TYPE.SECURED_DELETE,
			''
		);
	}

	updateUserMfaPreference(queryParam): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.profileApi.updateMfaPreferences}${queryParam}`,
			METHOD_TYPE.SECURED_POST,
			''
		);
	}

	getMfaActivatedStatus(email: string): Observable<MfaActivatedStatusResponse> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.profileApi.mfaActivationStatus + `?email=${email}`,
			METHOD_TYPE.GENERAL_GET,
			''
		)
	}


	setMfaActivatedStatus(email: string): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.profileApi.updateMfaActivationStatus + `?email=${email}`,
			METHOD_TYPE.GENERAL_POST,
			''
		)
	}

	forgotPasswordPreCheck(requestForm: ForgotPasswordPreCheckRequest): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.profileApi.forgotPasswordPreCheck}?email=${requestForm.email}`,
			METHOD_TYPE.GENERAL_POST,
			requestForm
		);
	}
}
