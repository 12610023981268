import { Injectable, Injector } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ServiceRouter } from './http-service-router.service';
import { Environment } from 'src/app/config/environment';
import { Config } from 'src/app/config/config';
import { METHOD_TYPE } from 'src/app/constants/service.constants';
import { MfaActivatedStatusResponse, MfaSessionStorageKeys, MfaSetupState, MfaUiCommands } from 'src/app/modules/shared/types';
import { ProfileService } from './profile.service';

@Injectable({
	providedIn: 'root',
})
export class MfaService {
	constructor(private injector: Injector) { }

	userFirstName: string = '';
	email: string = '';
	password: string = '';

	private displayMfaInputScreenSubject = new Subject<MfaUiCommands>();
	displayMfaInputScreen: Observable<MfaUiCommands> = this.displayMfaInputScreenSubject.asObservable();

	private mfaCodeSubject = new Subject<string>();
	mfaCode: Observable<string> = this.mfaCodeSubject.asObservable();

	private resendMfaCodeSubject = new Subject<boolean>();
	resendMfaCodeObserver: Observable<boolean> = this.resendMfaCodeSubject.asObservable();

	private showMfaErrorMessageSubject = new Subject<boolean>();
	showMfaErrorMessage: Observable<boolean> = this.showMfaErrorMessageSubject.asObservable();

	private showMfaErrorMessageValueSubject = new Subject<string>();
	mfaErrorMessage: Observable<string> = this.showMfaErrorMessageValueSubject.asObservable();

	private mfaACtivatedAtSubject = new Subject<string>();
	mfaActivatedAt = this.mfaACtivatedAtSubject.asObservable();

	private displayMfaSuccessScreenSubject = new Subject<MfaUiCommands>();
	displayMfaSuccessScreen: Observable<MfaUiCommands> = this.displayMfaSuccessScreenSubject.asObservable();

	private codeDeliveryDestinationSubject = new Subject<string>();
	codeDeliveryDestination = this.codeDeliveryDestinationSubject.asObservable();

	getEmail() {
		return this.email;
	}
	setEmail(username: string) {
		this.email = username;
	}

	getPassword() {
		return this.password;
	}
	setPassword(password: string) {
		this.password = password;
	}

	getUserFirstName() {
		return this.userFirstName;
	}
	setUserFirstName(username: string) {
		this.userFirstName = username;
	}

	toggleMfaSuccessScreen(uiCommand: MfaUiCommands) {
		this.displayMfaSuccessScreenSubject.next(uiCommand);
	}

	toggleDisplayMfaInputScreen(uiCommand: MfaUiCommands) {
		this.displayMfaInputScreenSubject.next(uiCommand);
	}

	displayMfaErrorMessage(showError: boolean, errorMessage: string) {
		this.showMfaErrorMessageSubject.next(showError);
		this.showMfaErrorMessageValueSubject.next(errorMessage);
	}

	setMfaCode(code: string) {
		this.mfaCodeSubject.next(code);
	}

	resendMfaCode(resend: boolean) {
		this.resendMfaCodeSubject.next(resend);
	}

	setMfaActivatedAt(status: string) {
		this.mfaACtivatedAtSubject.next(status);
	}

	setCodeDeliveryDestination(codeDeliveryDestination: string) {
		this.codeDeliveryDestinationSubject.next(codeDeliveryDestination);
	}

	fetchGlobalMfaStatus(): Promise<boolean> {
		console.info('Fetching global-mfa-status:');

		const config = this.injector.get(Config);
		const environment = this.injector.get(Environment);
		const serviceRouter = this.injector.get(ServiceRouter);

		return new Promise(resolve => {
			const subscription = serviceRouter
				.makeRequest(
					environment.getEnvironment().EPMS_PROXY_BASE_URL,
					config.url.global.mfaStatus,
					METHOD_TYPE.GENERAL_GET,
					''
				)
				.subscribe({
					next: response => {
						console.info('global-mfa-status:', response);
						if (response) {
							sessionStorage.setItem(MfaSessionStorageKeys.GLOBAL_MFA_STATUS, response.enabled);
							resolve(response.enabled);
						} else {
							resolve(false);
						}
						subscription.unsubscribe();
					},
					error: (error) => {
						console.error('Unable to fetch global-mfa-status:', error);
						sessionStorage.setItem(MfaSessionStorageKeys.GLOBAL_MFA_STATUS, 'false');
						resolve(false);
						subscription.unsubscribe();
					}
				});
		});
	}


	/**
	 * Fetch user's current MFA status using API and update in the service
	 * @param email
	 * @returns a promise that will resolve to `true` if already set \
	 * `false` if disabled by user \
	 * `undefined` if never set or error while fetching
	 */
	fetchUserMfaActivatedStatus(email): Promise<boolean|undefined> {
		const profileService = this.injector.get(ProfileService);
		this.setEmail(email);
		return new Promise(resolve => {
			const mfaActivatedStatusSubscription = profileService.getMfaActivatedStatus(email).subscribe({
				next: async (response: MfaActivatedStatusResponse) => {
					this.setUserFirstName(response?.username);
					this.ss_setUserMfaStatus(response?.isMfaActive);
					this.ss_setMfaActivatedAt(response?.hasMfaHistory ? MfaSetupState.ALREADY_SET : MfaSetupState.UNDEFINED);
					this.setMfaActivatedAt(response?.hasMfaHistory ? MfaSetupState.ALREADY_SET : MfaSetupState.UNDEFINED);
					mfaActivatedStatusSubscription.unsubscribe();
					resolve(response?.isMfaActive);
				},
				error: () => {
					this.setUserFirstName('User');
					this.ss_setUserMfaStatus(undefined);
					this.ss_setMfaActivatedAt(MfaSetupState.UNDEFINED);
					this.setMfaActivatedAt(MfaSetupState.UNDEFINED);
					mfaActivatedStatusSubscription.unsubscribe();
					resolve(undefined);
				},
			});
		});
	}

	ss_getGlobalMfaStatus() {
		if (localStorage.getItem('sso') === 'true') {
			return false;
		} else {
			return sessionStorage.getItem(MfaSessionStorageKeys.GLOBAL_MFA_STATUS) === 'true';
		}
	}

	ss_setGlobalMfaStatus(value: boolean) {
		if (localStorage.getItem('sso') === 'true') {
			sessionStorage.setItem(MfaSessionStorageKeys.GLOBAL_MFA_STATUS, 'false');
		} else {
			sessionStorage.setItem(MfaSessionStorageKeys.GLOBAL_MFA_STATUS, value?.toString() || 'false');
		}
	}

	ss_getUserMfaStatus() {
		return sessionStorage.getItem(MfaSessionStorageKeys.IS_MFA_SET_AT_USER_LEVEL) === 'true';
	}

	ss_setUserMfaStatus(value: boolean) {
		sessionStorage.setItem(MfaSessionStorageKeys.IS_MFA_SET_AT_USER_LEVEL, value?.toString() || 'false');
	}

	ss_getMfaActivatedAt() {
		return sessionStorage.getItem(MfaSessionStorageKeys.MFA_ACTIVATED_AT) || MfaSetupState.UNDEFINED;
	}

	ss_setMfaActivatedAt(value: string) {
		sessionStorage.setItem(MfaSessionStorageKeys.MFA_ACTIVATED_AT, value);
	}

	ss_getMfaDisabledReason() {
		return sessionStorage.getItem(MfaSessionStorageKeys.MFA_DISABLED_REASON);
	}

	ss_setMfaDisabledReason(value: any) {
		sessionStorage.setItem(MfaSessionStorageKeys.MFA_DISABLED_REASON, value)
	}
}
