import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ValidationUtilityService } from '../../modules/shared/utils/validation.util/validation-utility.service';
import { SpinnerComponent } from '../../modules/shared/spinner/spinner.component';
import { AuthService } from '../../services/core/auth.service';
import { Subscription } from 'rxjs';
import { AppUtil } from '../../utils/app.util';
import { FeedsService } from '../../services/dashboard/feeds.service';
import { AppointmentsService } from '../../services/scheduling/appointment/appointments.service';
import { DataCarrierService } from '../../services/core/data-carrier.service';
import { ProfileRelatedInfoBean } from '../../beans/account/account-module.bean';
import { Router, ActivatedRoute } from '@angular/router';
import { METHOD_TYPE } from '../../constants/service.constants';
import { Config } from '../../config/config';
import { Environment } from '../../config/environment';
import { ServiceRouter } from '../../services/core/http-service-router.service';
import { MigrationStatusService } from '../../services/migration/migration-status.service';
import emailMask from 'email-mask';
import { passwordSpecialCharsValidatorRegExp } from 'src/app/constants/app.constants';
import { ProfileService } from 'src/app/services/core/profile.service';
import { AUTH_COMPONENTS } from 'src/app/constants/scheduling.constants';
import { MfaService } from 'src/app/services/core/mfa.service';

/**
 * Activate account component
 */
@Component({
	selector: 'app-activate-account',
	templateUrl: './activate-account.component.html',
	styleUrls: ['./activate-account.component.scss'],
})
export class ActivateAccountComponent implements OnInit, OnDestroy {
	/** Activate account form */
	activateAccountForm: FormGroup;
	/** Flag to hide password strength block on focus */
	hidePasswordStrengthBlock: boolean = true;
	/** subscriptions used */
	subscriptions: Subscription[] = [];
	/** denote whether cognito account creation failed or not */
	accountCreationFailed: boolean = false;
	/** Return Url */
	returnUrl: string = '';
	/** holds user bean */
	userBean: any = {
		email: '',
		password: '',
		epmsPID: '',
		uuid: '',
		firstName: '',
		lastName: '',
	};
	/** flag to check if user already has myehe account activated */
	accountExists: boolean = false;
	/** error message */
	errorMessage: string = '';
	/** Custom Password Special Characters Validator RegExp */
	passwordSpecialCharsValidatorRegExp: RegExp = passwordSpecialCharsValidatorRegExp;

	/**
	 * Activate account constructor
	 * @param router - Angular router
	 * @param fb - form builder
	 * @param config - config where service url's are maintained
	 * @param environment - environment file
	 * @param serviceRouter - service router to make service calls
	 * @param authService - auth service to make cognito related calls
	 * @param spinner - spinner component - loader
	 * @param profileService - profile service to get profile info
	 * @param feedsService - feeds service to get feeds info
	 * @param appointmentService - appointment service to get appt info
	 * @param dataCarrierService - data carrier service where appt count is stored across application
	 * @param activatedRoute - route params
	 */
	constructor(
		private router: Router,
		private fb: FormBuilder,
		private config: Config,
		private environment: Environment,
		private serviceRouter: ServiceRouter,
		private authService: AuthService,
		private spinner: SpinnerComponent,
		private profileService: ProfileService,
		private feedsService: FeedsService,
		private appointmentService: AppointmentsService,
		private dataCarrierService: DataCarrierService,
		private activatedRoute: ActivatedRoute,
		private migrationService: MigrationStatusService,
		private readonly mfaService: MfaService,
	) {
		this.subscriptions.push(
			this.activatedRoute.queryParams.subscribe(params => {
				if (params && params.u) {
					this.getPatientInfoByTag(params.u);
				} else {
					this.router.navigate(['/']);
				}
			})
		);
	}

	/**
	 * On component initialization
	 */
	ngOnInit() {
		this.activateAccountForm = this.fb.group(
			{
				password: new FormControl(
					'',
					Validators.compose([Validators.required, ValidationUtilityService.passwordValidator])
				),
				confirmPassword: new FormControl(
					'',
					Validators.compose([Validators.required, ValidationUtilityService.passwordValidator])
				),
			},
			{
				validator: ValidationUtilityService.matchingPasswords.bind(this),
			}
		);
	}

	/**
	 * Gets patient info by patient tag
	 * @param tag patient tag
	 */
	getPatientInfoByTag(tag) {
		this.subscriptions.push(
			this.serviceRouter
				.makeRequest(
					this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
					`${this.config.url.patient.get_info_by_tag}?tag=${tag}`,
					METHOD_TYPE.GENERAL_GET,
					''
				)
				.subscribe(
					response => {
						this.userBean = response;
						if (!(response.epmsPID && response.uuid && response.email)) {
							this.accountExists = true;
							this.errorMessage = 'Welcome back!<br>Please sign in using your email and password.';
						}
					},
					error => {
						this.accountExists = true;
						this.errorMessage = 'Welcome back!<br>Please sign in using your email and password.';
						console.error(`[${ActivateAccountComponent.name}][${this.getPatientInfoByTag.name}]`, error);
					}
				)
		);
	}

	createAccount() {
		if (this.activateAccountForm.valid) {
			this.spinner.startLoader();
			this.userBean.email = AppUtil.getFormattedEmail(this.userBean.email);
			const body = {
				epmsPID: this.userBean.epmsPID,
				uuid: this.userBean.uuid,
				email: this.userBean.email,
				activateAccount: true,
			};
			this.serviceRouter
				.makeRequest(
					this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
					this.config.url.registrationApi.migrateAccount,
					METHOD_TYPE.GENERAL_POST,
					body
				)
				.subscribe(
					res => {
						this.createAccountInCognito();
					},
					err => {
						this.spinner.stopLoader();
					}
				);
		} else {
			this.activateAccountForm.get('password').markAsTouched();
			this.activateAccountForm.get('confirmPassword').markAsTouched();
		}
	}

	/**
	 * Creates account in cognito
	 */
	createAccountInCognito() {
		this.userBean.email = AppUtil.getFormattedEmail(this.userBean.email);
		const authCredentials = this.activateAccountForm.getRawValue();
		this.userBean.password = authCredentials.password;
		this.authService.signUpUser(this.userBean);
		this.subscriptions.push(
			this.authService.getSignUpResult().subscribe(
				res => {
					this.updateMigrationStatus();
				},
				error => {
					this.errorMessage =
						'We do not recognize your email address. <br>Please contact Member Services at 1.855.923.3562';
					console.error(
						`[${ActivateAccountComponent.name}][${this.createAccountInCognito.name}]`,
						'Error while creating user account in cognito',
						this.userBean.email,
						error
					);
					this.accountCreationFailed = true;
					this.spinner.stopLoader();
				}
			)
		);
	}

	/**
	 * Login the user immediately after creating account
	 */
	login() {
		console.info('in activate account component');
		this.authService.login(this.userBean.email, this.userBean.password, AUTH_COMPONENTS.SIGNUP_COMPONENT);
		this.subscriptions.push(
			this.authService.getResult().subscribe(response => {
				if (this.returnUrl !== '') {
					this.profileService.getProfileInfo().subscribe(
						(res: ProfileRelatedInfoBean) => {
							this.spinner.stopLoader();
							const profileInfo = res;
							if (profileInfo.profileInfo.userMatched) {
								if (this.returnUrl.includes('scheduling/create/appointment')) {
									sessionStorage.setItem('RETURN_URL', 'true');
								}
								this.router.navigateByUrl(`${this.returnUrl}`);
							} else {
								this.router.navigate(['dashboard']);
							}
						},
						err => {
							this.spinner.stopLoader();
						}
					);
				} else {
					AppUtil.redirectToScheduling(
						this.router,
						this.feedsService,
						this.appointmentService,
						this.dataCarrierService
					);
				}
			})
		);
	}

	/** Update account status on backend */
	updateAccountStatus() {
		this.subscriptions.push(
			this.serviceRouter
				.makeRequest(
					this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
					this.config.url.registrationApi.createAccount + `/${this.userBean.epmsPID}`,
					METHOD_TYPE.GENERAL_PUT,
					{
						uuid: this.userBean.uuid,
						email: this.userBean.email,
					}
				)
				.subscribe(
					response => {
						this.login();
						sessionStorage.setItem('createAccountToaster', '1');
					},
					error => {
						this.spinner.stopLoader();
						console.error(
							`[${ActivateAccountComponent.name}][${this.updateAccountStatus.name}]`,
							error,
							this.userBean.email,
							this.userBean.uuid
						);
					}
				)
		);
	}

	/** Unsubscribing all the subscriptions used */
	ngOnDestroy() {
		// prevent memory leak when component destroyed
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
		this.subscriptions = [];
	}

	/**
	 * Updates migration status of status
	 */
	updateMigrationStatus() {
		const body = {
			epmsPID: this.userBean.epmsPID,
			email: this.userBean.email,
			activateAccount: true,
		};
		this.migrationService.updateMigrationStatus(body).subscribe(
			res => {
				this.updateAccountStatus();
			},
			err => {
				this.spinner.stopLoader();
				console.error(
					`[${ActivateAccountComponent.name}][${this.updateMigrationStatus.name}]`,
					'An error occurred while updating account status'
				);
			}
		);
	}

	getMaskedEmail() {
		if (this.userBean && this.userBean.email) {
			return emailMask(this.userBean.email, '*');
		}
	}

	getGlobalMfaStatus() {
		return this.mfaService.ss_getGlobalMfaStatus();
	}
}
