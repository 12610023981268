<div class="auth-content-wrapper">
	<div class="auth-content">
		<app-value-proposition></app-value-proposition>
	</div>
</div>
<div class="content-box form-wrapper" *ngIf="!displayMfaInputScreen">
	<h2
		class="align-center {{ isForceReset ? 'section-heading' : 'default' }}"
		*ngIf="!ifAnyError"
		[translate]="'registration.reset-password.title'">
		Reset Password
	</h2>
	<div class="auth-content">
		<p
			class="align-center"
			[translate]="'registration.code-verification.description'"
			*ngIf="isForceReset && !codeResent && !ifAnyError">
			A 6-digit verification code was just sent to {{ userEmailID }}. Please enter it below.
		</p>
		<p class="align-center" *ngIf="codeResent && !ifAnyError">
			We have emailed you a new verification code. Please enter it below.
		</p>
		<p class="align-center font-red" *ngIf="verificationCodeError || ifLimitExceededError">{{ errorMsg }}</p>
	</div>
	<div class="row" *ngIf="ifAnyError">
		<div class="col align-center section-heading text-16">
			<div *ngIf="ifAnyError" [innerHTML]="errorMsg"></div>
			<div class="col">
				<div class="center">
					<button
						class="button button-medium"
						mat-button
						appEheAnalytics
						[data]="{
							pageUrl: '/reset/password',
							redirectedTo: '/login',
							componentName: 'reset-password',
							id: 'Reset password Back to Login',
							placement: 'Reset password'
						}"
						[routerLink]="['/']"
						[translate]="'registration.reset-password.links.back-to-login'">
						Back To Login
					</button>
				</div>
			</div>
		</div>
	</div>
	<div [formGroup]="resetPasswordForm" *ngIf="!ifAnyError" aria-autocomplete="none">
		<div class="row" *ngIf="isForceReset">
			<div class="col-12">
				<mat-form-field class="form-group">
					<mat-label>{{ 'registration.reset-password.form.verification-code' | translate }}</mat-label>
					<input matInput formControlName="verificationCode" type="text" maxlength="6" />
					<mat-error>
						<app-error-message [control]="'verificationCode'"></app-error-message>
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12 password-wrapper">
				<mat-form-field class="form-group">
					<mat-label>{{ 'registration.reset-password.form.new-password' | translate }}</mat-label>
					<input
						#password
						matInput
						formControlName="password"
						type="password"
						appShowHidePassword
						maxlength="32"
						(focusin)="hidePasswordStrengthBlock = false" />
				</mat-form-field>
				<div class="strength-wrapper {{ hidePasswordStrengthBlock ? 'hide' : '' }}">
					<mat-hint class="hint" align="end" aria-live="polite"> {{ password.value.length }} / 32 </mat-hint>
					<mat-password-strength
						#passwordComponent
						[password]="password.value"
						[enableLengthRule]="true"
						[enableSpecialCharRule]="false"
						[customValidator]="passwordSpecialCharsValidatorRegExp"></mat-password-strength>
					<password-strength-info
						[passwordComponent]="passwordComponent"
						digitsCriteriaMsg="{{ 'shared.password.digits-rule' | translate }}"
						upperCaseCriteriaMsg="{{ 'shared.password.uppercase-rule' | translate }}"
						lowerCaseCriteriaMsg="{{ 'shared.password.lowercase-rule' | translate }}"
						customCharsCriteriaMsg="{{ 'shared.password.special-character-rule' | translate }}">
					</password-strength-info>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 {{ !hidePasswordStrengthBlock ? 'pt-3' : '' }} {{ checkIfConfirmPasswordIsValid() }}">
				<mat-form-field class="form-group">
					<mat-label>{{ 'registration.reset-password.form.confirm-password' | translate }}</mat-label>
					<input
						matInput
						formControlName="confirmPassword"
						type="password"
						appShowHidePassword
						maxlength="32"
						(focusin)="hidePasswordStrengthBlock = true" />
					<mat-error>
						<app-error-message [control]="'confirmPassword'"></app-error-message>
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div *ngIf="getEffectiveMfaStatus()" class="col-12 py-3">
				<mat-label style="color: #0053a7">TWO-FACTOR SIGN IN</mat-label>
				<p style="font-size: 13px; padding-top: 5px">
					Two-factor sign in will be enabled to provide an additional layer of security for your myEHE account. You can
					manage your sign-in preferences on the My Account page on myEHE.
				</p>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="center">
					<button
						*ngIf="!isForceReset"
						class="button button-medium"
						mat-button
						appEheAnalytics
						[data]="analyticsData"
						(click)="resetPassword(); hidePasswordStrengthBlock = true">
						<span [translate]="'registration.reset-password.form.reset-button'">Reset</span>
					</button>
					<button
						*ngIf="isForceReset"
						class="button button-medium"
						mat-button
						appEheAnalytics
						[data]="analyticsData"
						(click)="resetPassword()">
						<span [translate]="'registration.reset-password.form.submit-button'">Submit</span>
					</button>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="isForceReset">
			<div class="col-12">
				<div class="center">
					<span
						class="small-text text-right text-link clickable"
						appEheAnalytics
						[data]="analyticsData"
						(click)="requestVerificationCode()"
						[translate]="'registration.reset-password.links.resend-verification-code'"
						>Resend Verification Code?</span
					>
				</div>
			</div>
		</div>
	</div>
</div>
